import React, { useState } from "react";
import classnames from "classnames/bind";

import styles from "./Sponsors.scss";
import ThankYouCards from "assets/AVW-2020-Thank-You-Cards.zip";
import LetterHeads from "assets/AVW-2020-Letterheads.zip";
import SponsorshipPackage from "assets/AVW-SPONSORSHIP-PACKAGE-2020.pdf";

const c = classnames.bind(styles);

const CONSTANTS = {
  YEAR_1: "1 year",
  EVENT: "Event Duration",
  FINISH: "Finish Line Broadcast",
  YEAR_END: "Year-end",
  EMAIL: "ancopwalk@ancopcanada.org",
  trendsetter: "Trendsetter",
  lifeline: "Lifeline",
  gamechanger: "Gamechanger",
  equalizer: "Equalizer",
};

const TEXT = {
  companysite: { value: "ANCOP Canada Website", tooltip: "" },
  campaignsite: {
    value: "AVW Website &\nRegistration Page",
    tooltip: "",
  },
  app: { value: "AVW Mobile Challenge App", tooltip: "" },
  social: { value: "ANCOP Social Media", tooltip: "" },
  local: { value: "Local Promotion", tooltip: "" },
  carepackage: { value: "Sponsor Care Package", tooltip: "" },
  appaccess: { value: "Access to Virtual Walk App", tooltip: "" },
  blog: { value: "Sponsor Feature Blog", tooltip: "" },
  logo: { value: "Logo Placement", tooltip: "" },
  logosite: { value: "Logo on Campaign Website", tooltip: "" },
  logoregistration: {
    value: "Logo on Registration Page",
    tooltip: "",
  },
  content: { value: "Sponsored Content", tooltip: "" },
  announcements: { value: "Sponsored Announcements", tooltip: "" },
  livestream: { value: "Live Stream Program Plug", tooltip: "" },
  facebook: { value: "ANCOP Canada Facebook Posts", tooltip: "" },
  fundraising: {
    value: "Mentions in local fundraising activities",
    tooltip: "",
  },
  radio: { value: "Mentions in local radio broadcast", tooltip: "" },
  report: { value: "Program and Impact Report", tooltip: "" },
  team: { value: "Option to Create Own Team", tooltip: "" },
  participants: { value: "Sponsored Participants", tooltip: "" },
};

const config = {
  equalizer: {
    price: "10,000",
    sections: {
      companysite: {
        blog: 4,
        logo: CONSTANTS.YEAR_1,
      },
      campaignsite: {
        logosite: CONSTANTS.EVENT,
        logoregistration: CONSTANTS.EVENT,
      },
      app: {
        content: 8,
        announcements: 8,
      },
      social: {
        livestream: CONSTANTS.FINISH,
        facebook: 4,
      },
      local: {
        fundraising: CONSTANTS.EVENT,
        radio: null,
      },
      carepackage: {
        report: CONSTANTS.YEAR_END,
      },
      appaccess: {
        team: "Yes",
        participants: 20,
      },
    },
  },
  gamechanger: {
    price: 5000.0,
    sections: {
      companysite: {
        blog: 2,
        logo: CONSTANTS.YEAR_1,
      },
      campaignsite: {
        logosite: CONSTANTS.EVENT,
        logoregistration: CONSTANTS.EVENT,
      },
      app: {
        content: 4,
        announcements: 4,
      },
      social: {
        livestream: null,
        facebook: 2,
      },
      local: {
        fundraising: CONSTANTS.EVENT,
        radio: null,
      },
      carepackage: {
        report: CONSTANTS.YEAR_END,
      },
      appaccess: {
        team: "Yes",
        participants: 10,
      },
    },
  },
  lifeline: {
    price: 2500.0,
    sections: {
      companysite: {
        blog: 1,
        logo: CONSTANTS.EVENT,
      },
      campaignsite: {
        logosite: CONSTANTS.EVENT,
        logoregistration: CONSTANTS.EVENT,
      },
      app: {
        content: 2,
        announcements: 2,
      },
      social: {
        livestream: null,
        facebook: 1,
      },
      local: {
        fundraising: CONSTANTS.EVENT,
        radio: null,
      },
      carepackage: {
        report: CONSTANTS.YEAR_END,
      },
      appaccess: {
        team: "No",
        participants: 6,
      },
    },
  },
  trendsetter: {
    price: 1000.0,
    sections: {
      companysite: {
        blog: null,
        logo: CONSTANTS.EVENT,
      },
      campaignsite: {
        logosite: CONSTANTS.EVENT,
        logoregistration: CONSTANTS.EVENT,
      },
      app: {
        content: 1,
        announcements: 1,
      },
      social: {
        livestream: null,
        facebook: null,
      },
      local: {
        fundraising: CONSTANTS.EVENT,
        radio: null,
      },
      carepackage: {
        report: CONSTANTS.YEAR_END,
      },
      appaccess: {
        team: "No",
        participants: 2,
      },
    },
  },
};

function Sponsors({ isDesktop, isPhone, isMobile }) {
  const [selectedPackage, setPackage] = useState("trendsetter");
  const isProperDesktop = isDesktop && window.innerWidth >= 1201;

  const handleSelection = (e) => {
    setPackage(e.target.id);
  };

  const emailMsg = (selectPackage) => {
    const tier = selectPackage
      ? CONSTANTS[selectedPackage]
      : CONSTANTS[selectPackage];

    if (isDesktop)
      return `mailto:${CONSTANTS.EMAIL}?subject=AVW%20Sponsor:%20Interested%20in%20becoming%20a%20${tier}&body=Hi%20ANCOP%20Canada,%0D%0A%0D%0AI%20am%20interested%20in%20becoming%20a%20sponsor%20for%20the%20ANCOP%20Virtual%20Walk.%20I%20have%20provided%20additional%20information%20below:%0D%0A%0D%0AName%20(required):%0D%0ACompany%20(required):%20%0D%0ATelephone%20(required):%0D%0AAddress%20(required):%0D%0AHow%20Did%20You%20Hear%20About%20Us?%0D%0ASponsorship%20Tier:%20${tier}%0D%0A%0D%0A`;
    return `mailto:${CONSTANTS.EMAIL}?subject=AVW%20Sponsor:%20Interested%20in%20becoming%20a%20${tier}&body=Hi%20ANCOP%20Canada,<br /><br />I%20am%20interested%20in%20becoming%20a%20sponsor%20for%20the%20ANCOP%20Virtual%20Walk.%20I%20have%20provided%20additional%20information%20below:<br /><br />Name%20(required):<br />Company%20(required):%20<br />Telephone%20(required):<br />Address%20(required):<br />How%20Did%20You%20Hear%20About%20Us?<br />Sponsorship%20Tier:%20${tier}<br /><br />`;
  };

  return (
    <div className={c("sponsors")}>
      <h1 className={c("sponsors-title")}>Become A Sponsor</h1>
      <p className={c("sponsors-title-caption")}>
        Get involved and be a partner in our mission. A variety of branding
        opportunities are available for sponsors.
      </p>
      <section className={c("sponsors-table")}>
        {/* MOBILE/DESKTOP - TIERS */}
        {Object.keys(config).map((tier) => {
          return (
            <>
              <article
                key={tier}
                className={c("sponsors-section-card", {
                  selected: tier === selectedPackage,
                })}
              >
                <h2 className={c("sponsors-tier-title")}>
                  {String(tier).toUpperCase() || ""}
                </h2>
                {Object.keys(config[tier].sections).map((section, i) => {
                  if (section !== "price") {
                    return (
                      <div
                        key={`${config[tier].sections[section]}-${i}`}
                        className={c("sponsors-section")}
                      >
                        {!isProperDesktop && (
                          <p className={c("sponsors-section-title")}>
                            {TEXT[section].value}
                          </p>
                        )}
                        {Object.keys(config[tier].sections[section]).map(
                          (type, i) => {
                            return (
                              <p
                                key={`${type}-${i}`}
                                className={c("sponsors-section-row")}
                              >
                                {!isProperDesktop && (
                                  <span className={c("sponsors-content-type")}>
                                    {TEXT[type].value}
                                  </span>
                                )}
                                <span className={c("sponsors-content-value")}>
                                  {config[tier].sections[section][type] ||
                                    "N/A"}
                                </span>
                              </p>
                            );
                          },
                        )}
                      </div>
                    );
                  }
                  return null;
                })}
                <p className={c("sponsors-section-row", "price")}>
                  ${config[tier].price}
                </p>
                {isProperDesktop ? (
                  <button
                    id={`${tier}`}
                    className={c("sponsors-cta")}
                    onClick={handleSelection}
                  >
                    Select
                  </button>
                ) : (
                  <a
                    className={c("sponsors-cta")}
                    href={emailMsg(CONSTANTS.equalizer)}
                  >
                    {`${
                      tier === "equalizer"
                        ? "Become an Equalizer"
                        : `Become a ${tier}`
                    }`}
                  </a>
                )}
              </article>
            </>
          );
        })}
        {/* DESKTOP ONLY - Comparison label Y-axis */}
        {isProperDesktop && (
          <article className={c("sponsors-section-card", "label")}>
            <h2 className={c("sponsors-tier-title")}> </h2>
            {Object.keys(config.equalizer.sections).map((section, i) => {
              if (section !== "price") {
                return (
                  <div
                    key={`${config.equalizer.sections[section]}-${i}`}
                    className={c("sponsors-section")}
                  >
                    {/* {!isProperDesktop && ( */}
                    <p className={c("sponsors-section-title")}>
                      {TEXT[section].value}
                    </p>
                    {/* )} */}
                    {Object.keys(config.equalizer.sections[section]).map(
                      (type, i) => {
                        return (
                          <p
                            key={`${type}-${i}`}
                            className={c("sponsors-section-row")}
                          >
                            <span className={c("sponsors-content-type")}>
                              {TEXT[type].value}
                            </span>
                          </p>
                        );
                      },
                    )}
                  </div>
                );
              }
              return null;
            })}
            <p className={c("sponsors-section-row", "price")}> </p>
          </article>
        )}
      </section>
      {isProperDesktop && (
        <a
          className={c("sponsors-cta", "form")}
          href={emailMsg(selectedPackage)}
        >
          Sponsor now
        </a>
      )}
      <section className={c("sponsors-resources")}>
        <h2>Sponsorship Resources</h2>
        <div className={c("sponsors-resources-container")}>
          <a href={SponsorshipPackage} className={c("sponsors-cta")}>
            Download Complete Sponsorship Package
          </a>
          <a href={ThankYouCards} className={c("sponsors-cta")}>
            Download Thank You Cards
          </a>
          <a href={LetterHeads} className={c("sponsors-cta")}>
            Download Solicitation Letter Heads
          </a>
        </div>
      </section>
    </div>
  );
}

export default Sponsors;
