import React from "react";
import classnames from "classnames/bind";

import styles from "./FAQ.scss";
const c = classnames.bind(styles);

const FAQ = () => {
  return (
    <div className={c("faq")}>
      <h1 className={c("faq-title")}>FAQ</h1>
      <p className={c("faq-title-caption")}>
        We've gathered the common questions that have arrived from the ANCOP
        Walk over the years and a few more especially for the Virtual Walk.
      </p>
      <section>
        <h2 className={c("faq-section-title")}>General FAQ</h2>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>
            Who can take part in the ANCOP Virtual Walk?
          </h3>
          <p className={c("faq-answer")}>
            The walk is open to everyone - individuals, families, friends &amp;
            other organizations. Those under the age of 18 must be supervised by
            an adult. Remember to follow social distancing rules in your
            respective locations.
          </p>
        </div>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>How do I register?</h3>
          <p className={c("faq-answer")}>
            Register via{" "}
            <a
              href="https://www.ancopvirtualwalk.com/register"
              aria-label="Register for ANCOP Virtual walk"
            >
              ancopvirtualwalk.com/register
            </a>
            .
          </p>
        </div>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>
            How much is the registration fee?
          </h3>
          <p className={c("faq-answer")}>
            Registration fee is $20.00 for adults and $10.00 for students (13 to
            18 years of age). You can pay online via Visa or Mastercard. You
            will receive an email confirmation that serves as your receipt. Note
            that the registration fee is not entitled to a tax receipt.
          </p>
        </div>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>
            I didn’t receive an email confirmation of my registration. Where can
            I find it?
          </h3>
          <p className={c("faq-answer")}>
            The email may have gone into your spam or junk mail. Please add
            message@e2rm.com to your address books.
          </p>
        </div>
      </section>
      <section>
        <h2 className={c("faq-section-title")}>FUNDRAISING AND DONATIONS</h2>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>How much do I need to raise?</h3>
          <p className={c("faq-answer")}>
            Set yourself a personal target. If you are a part of a team, the
            funds that you raised will be added to your team’s overall
            performance. Here are some specific targets that might interest you
            or your team:
            <ul>
              <li>$ 456 sponsors a child’s education for 1 year</li>
              <li>$ 4,560 sponsors a batch of 10 children</li>
              <li>$ 6,000 builds 1 house</li>
              <li>$ 180,000 builds an ANCOP Community</li>
            </ul>
          </p>
        </div>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>
            How do I fundraise using my Personal page?
          </h3>
          <p className={c("faq-answer")}>
            Go to Fundraising and click Get Sponsors. Choose from two options
            how you will do your campaign: Send emails or Share with Social
            Media Sites.
          </p>
        </div>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>
            My donors can’t see my page when they click on the Donate Now button
            on the Walk page.
          </h3>
          <p className={c("faq-answer")}>
            My donors can’t see my page when they click on the Donate Now button
            on the Walk page.
          </p>
        </div>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>Do you issue tax receipts?</h3>
          <p className={c("faq-answer")}>
            Yes. Online donations (of $20 or more) will be issued a tax receipt
            (Canadian residents only) via e-mail.
          </p>
        </div>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>
            Who do I contact if I haven’t received my income tax receipt?
          </h3>
          <p className={c("faq-answer")}>
            If you haven’t received an income tax receipt by end of February the
            next year, please email accounting@ancopcanada.org
          </p>
        </div>
      </section>
      <section>
        <h2 className={c("faq-section-title")}>VIRTUAL WALK</h2>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>What do I need to participate?</h3>
          <p className={c("faq-answer")}>
            You just need a smartphone and/or a fitness tracking device. Those
            supported are in this{" "}
            <a href="http://help.movespring.com/en/articles/1419827-what-fitness-trackers-and-smartwatches-can-play">
              help article
            </a>
            .
          </p>
        </div>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>How do I access the app?</h3>
          <p className={c("faq-answer")}>
            On your mobile phone browser, access your fundraising page and tap
            the “CONNECT” button. From there, you will be asked to download the
            MOVESPRING app on the App Store or Google Play.
          </p>
        </div>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>
            I now have the app on my phone, what do I do next?
          </h3>
          <p className={c("faq-answer")}>
            Tap on “SIGN UP” and complete your MOVESPRING profile.
          </p>
        </div>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>
            The app is asking for an organization code, where can I get it?
          </h3>
          <p className={c("faq-answer")}>
            Go back to your fundraising page, tap the “CONNECT” button, and sign
            up on MOVESPRING again. The organization code should pre-populate
            for you.
          </p>
        </div>
        <div className={c("faq-content")}>
          <h3 className={c("faq-question")}>
            I’m having trouble connecting and syncing my device to MOVESPRING,
            where can I get help?
          </h3>
          <p className={c("faq-answer")}>
            Tap on “MESSAGE SUPPORT” on the MOVESPRING app to get support on
            your device.
          </p>
        </div>
      </section>
    </div>
  );
};

export default FAQ;
