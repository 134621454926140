import React, { useEffect, useState } from "react";
import MobileDetect from "mobile-detect";
import moment from "moment";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Desktop from "./Desktop";
import Phone from "./Phone";
import Countdown from "./Countdown";
import Sponsors from "./Sponsors";
import Instructions from "./Instructions";
import FAQ from "./FAQ";
// import Menu from "./Menu";

import styles from "./App.scss";
import classnames from "classnames/bind";
const c = classnames.bind(styles);

const md = new MobileDetect(window.navigator.userAgent);
var isScrolling;

function App() {
  const isPhone = md.phone();
  const isTablet = md.tablet();
  const isDesktop = !(md.phone() || md.tablet());

  const [currentY, setScrollY] = useState(window.scrollY);
  const [firstScroll, scrolledOnce] = useState(false);
  const [firstClick, clickedOnce] = useState(false);

  function handleScroll(e) {
    if (!firstScroll) scrolledOnce(true);
    setScrollY(window.scrollY);
  }

  const handleClick = (e) => {
    if (!firstClick) clickedOnce(true);
  };

  useEffect(() => {
    if (isPhone || isTablet) {
      window.addEventListener("touchmove", handleScroll);
      window.addEventListener("scroll", handleScroll);
    } else if (isDesktop) {
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("click", handleClick);
    }

    return () => {
      window.removeEventListener("touchmove", handleScroll);
      window.removeEventListener("scroll", handleScroll);
      window.addEventListener("click", handleClick);
    };
    // eslint-disable-next-line
  }, [isPhone, isTablet, isDesktop]);

  const w = window.innerWidth;

  const today = moment();
  const launchDate = moment("2020-05-29");

  const isLaunched = today.diff(launchDate) > 0;
  return (
    <div className="app">
      <Router>
        {/* <Menu active={currentY > 10} isDesktop={isDesktop} /> */}
        <Switch>
          <Route path="/faq">
            <FAQ isPhone={isPhone} isTablet={isTablet} isDesktop={isDesktop} />
          </Route>
          <Route path="/instructions">
            <Instructions
              isPhone={isPhone}
              isTablet={isTablet}
              isDesktop={isDesktop}
            />
          </Route>
          <Route path="/sponsors">
            <Sponsors
              isPhone={isPhone}
              isTablet={isTablet}
              isDesktop={isDesktop}
            />
          </Route>
          <Route path="/watch">
            <div className="youtube-live">
              <iframe
                title="ANCOP Virtual Walk 2020 | Live "
                width={w - w / 4}
                height={window.innerHeight - window.innerHeight / 4}
                src="https://www.youtube-nocookie.com/embed/06XwW0LNZcM?controls=0"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <a
                className={c("donate-cta")}
                href="https://secure.e2rm.com/registrant/Donate.aspx?eventid=335408&langpref=en-CA"
                alt="Make a donation to help the poor"
              >
                Donate Now
              </a>
            </div>
          </Route>
          <Route exact path="/">
            <>
              {isLaunched ? (
                <>
                  {(md.phone() || md.tablet() || w < 769) && <Phone />}
                  {!(md.phone() || md.tablet()) && w > 768 && (
                    <Desktop
                      y={currentY}
                      firstScroll={firstScroll}
                      firstClick={firstClick}
                      isScrolling={isScrolling}
                    />
                  )}
                </>
              ) : (
                <Countdown />
              )}
            </>
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
