import React from "react";
import classnames from "classnames/bind";

import heroImg from "assets/hero.png";
// import phoneImg from "assets/phone.png";
// import { ReactComponent as Racers } from "assets/racers.svg";
// import { ReactComponent as Sponsors } from "assets/sponsors.svg";
// import logoBigImg from "assets/LogoBig.png";
// import { sponsorLogos, donorList } from "./constants";

// import CompanyLogo from "./components/CompanyLogo";

import styles from "./Phone.scss";
const c = classnames.bind(styles);

function Phone(props) {
  return (
    <div className={c("phone")}>
      <section className={c("hero")}>
        <img className={c("hero-bg")} src={heroImg} alt="" />
        <div className={c("hero-content")}>
          <h1 className={c("hero-title")}>
            ANCOP
            <br />
            Virtual Walk
          </h1>
          <div className={c("hero-caption")}>
            <p>
              Thank you for your interest. Registrations and donations for the
              2020 ANCOP Virtual Walk have closed. We look forward to seeing you
              in 2021. Stay tuned for more updates.
            </p>
            <p>
              Relive the first-ever virtual staging of the ANCOP Walk by
              watching the online closing event:
            </p>
          </div>
          <a
            className={c("watch-link")}
            href="https://www.youtube.com/watch?v=06XwW0LNZcM"
          >
            Watch Now
          </a>
        </div>
      </section>
      {/* <section className={c("phone-walk")}>
        <Racers />
        <div className={c("phone-walk-content")}>
          <p>
            In light of the global COVID19 pandemic and the need to practice
            social distancing, the 17th annual ANCOP Walk will be held virtually
            from July 1 to August 22, 2020.
          </p>
          <p>
            This year's walkers will be able to join from the comfort of their
            own neighborhoods. During this 8-week period, walkers get to
            virtually track steps and make them count against millions of steps
            nationwide.
          </p>
        </div>
      </section>
      <section className={"phone-register"}>
        <img className={c("phone-bg")} src={phoneImg} alt="mobile phone app" />
        <div className={"phone-register-content"}>
          <h2>
            WALK
            <br />
            WITH US
          </h2>
          <p>
            Join over 5000 walkers all over Canada who show their support and
            consequently, help improve the lives of the poor.
          </p>
          <div className={c("phone-register-cta-container")}>
            <a
              className={c("phone-register-cta")}
              href="https://secure.e2rm.com/registrant/startup.aspx?eventid=335408"
              aria-label="Register for ANCOP Virtual Walk"
            >
              Register Now
            </a>
            <a
              className={c("phone-register-cta")}
              href="https://www.ancopvirtualwalk.com/login"
              aria-label="Login to the ANCOP Virtual Walk Fundraiser Page"
            >
              Login
            </a>
          </div>
        </div>
      </section>
      <section className={c("phone-sponsors")}>
        <div className={c("phone-sponsors-content")}>
          <h2>OUR SPONSORS</h2>
          <div className={c("phone-sponsors-logos")}>
            {sponsorLogos.equalizer.length > 0 && (
              <>
                <h3>Equalizer</h3>
                <div className={c("phone-sponsors-equalizer")}>
                  {sponsorLogos.equalizer.map((sponsor, i) => {
                    return (
                      <CompanyLogo key={i} type="equalizer" {...sponsor} />
                    );
                  })}
                </div>
              </>
            )}
            {sponsorLogos.gamechanger.length > 0 && (
              <>
                <h3>Game Changer</h3>
                <div className={c("phone-sponsors-gamechanger")}>
                  {sponsorLogos.gamechanger.map((sponsor, i) => {
                    return (
                      <CompanyLogo key={i} type="gamechanger" {...sponsor} />
                    );
                  })}
                </div>
              </>
            )}
            {sponsorLogos.lifeline.length > 0 && (
              <>
                <h3>Life Line</h3>
                <div className={c("phone-sponsors-lifeline")}>
                  {sponsorLogos.lifeline.map((sponsor, i) => {
                    return <CompanyLogo key={i} type="lifeline" {...sponsor} />;
                  })}
                </div>
              </>
            )}
            {sponsorLogos.trendsetter.length > 0 && (
              <>
                <h3>Trendsetter</h3>
                <div className={c("phone-sponsors-trendsetter")}>
                  {sponsorLogos.trendsetter.map((sponsor, i) => {
                    return (
                      <CompanyLogo key={i} type="trendsetter" {...sponsor} />
                    );
                  })}
                </div>
              </>
            )}
            <h3>Donor List</h3>
            <div className={c("phone-sponsors-donor-list")}>
              {donorList.map((donor) => (
                <span key={donor}>{donor}</span>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className={c("phone-donate")}>
        <div className={c("phone-donate-content")}>
          <h2>DONATE</h2>
          <p>
            Can't join the virtual walk? You can still help our mission by
            making a donation below.
          </p>
          <div className={c("phone-donate-logo")}>
            <img src={logoBigImg} alt="Answer the cry of the poor" />
          </div>
          <a
            className={c("phone-donate-cta")}
            href="https://secure.e2rm.com/registrant/mobile/mobileDonate.aspx?eventID=335408&langpref=en-CA&referrer=http%3a%2f%2flocalhost%3a3000%2f"
            alt="Make a donation to help the poor"
          >
            Donate Now
          </a>
        </div>
      </section>
      <section className={c("phone-become-sponsor")}>
        <div className={c("phone-become-sponsor-content")}>
          <h2>BECOME A SPONSOR</h2>
          <p>
            Get involved and be a partner in our mission. A variety of branding
            opportunities are available for sponsors.
          </p>
          <Sponsors />
          <a
            className={c("phone-become-sponsor-cta")}
            href="/sponsors"
            alt="learn more about becoming a sponsor"
          >
            More Info
          </a>
        </div>
      </section>
     */}
    </div>
  );
}

export default Phone;
