import React, { useEffect, useState } from "react";
import classnames from "classnames/bind";

import styles from "./Countdown.scss";
const c = classnames.bind(styles);

function Countdown(props) {
  const calculateTimeLeft = () => {
    const difference = new Date("2020-05-31") - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hrs: Math.floor((difference / (1000 * 60 * 60)) % 24),
        min: Math.floor((difference / 1000 / 60) % 60),
        sec: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval, i) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={i} className={`date-${i}`}>
        {timeLeft[interval]}{" "}
        {interval === "days" ? (
          "DAYS"
        ) : (
          <span className="unit-type">{interval}</span>
        )}{" "}
      </span>,
    );
  });

  return (
    <div className={c("countdown-container")}>
      <h1>ANCOP WALK</h1>
      <p>goes virtual in</p>
      <div className={c("countdown")}>
        <div className={c("countdown-wrapper")}>
          {timerComponents.length ? (
            timerComponents
          ) : (
            <span>It's time to walk!</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default Countdown;
