import React, { useRef, useEffect, useState } from "react";
import classnames from "classnames/bind";

import heroVideo from "assets/hero.mp4";
// import { ReactComponent as AncopWalkLogo } from "assets/ancopwalk-logo.svg";
import phoneImg from "assets/phone.png";
import { ReactComponent as RaceOne } from "assets/racer-1.svg";
import { ReactComponent as RaceTwo } from "assets/racer-2.svg";
import { ReactComponent as RaceThree } from "assets/racer-3.svg";
// import { ReactComponent as Sponsors } from "assets/sponsors.svg";
// import { ReactComponent as Arrow } from "assets/arrow.svg";
// import logoBigImg from "assets/LogoBig.png";
// import { sponsorLogos, donorList } from "./constants";

// import CompanyLogo from "./components/CompanyLogo";

import styles from "./Desktop.scss";
const c = classnames.bind(styles);

// TRIGGER ANIMATIONS
const HERO_POSITION = 700;

const Parallax = ({ y = 0, w, isLoaded, firstScroll, firstClick, marker }) => {
  const [videoStatus, setVideoStatus] = useState(null);
  let heroRef = useRef(null);
  let registerRef = useRef(null);

  // HERO VIDEO
  useEffect(() => {
    // const firstInteractionHappened = firstScroll && firstClick;

    if (
      // (firstScroll || firstClick) &&
      // !firstInteractionHappened &&
      isLoaded &&
      heroRef.current
    ) {
      heroRef.current.playbackRate = 2.25;
      heroRef.current.play();
      heroRef.current.addEventListener("ended", () => {
        setVideoStatus("ended");
      });
    }
    // eslint-disable-next-line
  }, [isLoaded, firstScroll, firstClick]);

  useEffect(() => {
    if (y < HERO_POSITION && videoStatus === "ended") {
      heroRef.current.currentTime = 11.795117 - (y / HERO_POSITION) * 11.795117;
    }
    // eslint-disable-next-line
  }, [y]);

  // PARALLAX_SPEED is relative to the y
  const PARALLAX_SPEED = {
    1: 4,
    2: 4,
  };

  // RACER POSITIONS
  const firstLeg = marker[1];
  // const secondLeg = marker[1] + 500;
  // const thirdLeg = marker[1] + 1000;
  const withinRacerSection = y > firstLeg && y < firstLeg + 800;

  const RACER_SPEED = {
    // LOWER = FASTER - Digits are positions for 3rd leg, 2nd leg, 1st leg
    // 1: y > thirdLeg ? 42 : y > secondLeg ? 52 : 68,
    // 2: y > thirdLeg ? 45 : y > secondLeg ? 55 : 60,
    // 3: y > thirdLeg ? 48 : y > secondLeg ? 49 : 65,
    1: 69,
    2: 62,
    3: 65,
  };
  const racer1top = withinRacerSection ? ((y * 10) / RACER_SPEED[1]) * 3 : 0;
  const racer2top = withinRacerSection ? ((y * 10) / RACER_SPEED[2]) * 3 : 0;
  const racer3top = withinRacerSection ? ((y * 10) / RACER_SPEED[3]) * 3 : 0;

  const RACER_START =
    w > 769 ? (w > 1025 ? (w > 1201 ? 1200 : 1200) : 1200) : 1200;

  // const REGISTER_START =
  //   w > 769 ? (w > 896 ? (w > 1025 ? (w > 1201 ? 1.2 : 1.4) : 1.2) : 1.7) : 2.1;

  return (
    <div className={c("bg")}>
      {/* SECTION 1 */}
      <div className={c("bg-hero")}>
        <video
          className={c("bg-hero-item")}
          style={{
            top: y / PARALLAX_SPEED[1],
          }}
          src={heroVideo}
          controls={false}
          ref={heroRef}
          muted="muted"
        />
        <div
          className={c("bg-hero-cover")}
          style={{
            top: y / PARALLAX_SPEED[1],
          }}
        ></div>
      </div>
      {/* SECTION 2 */}
      <div
        className={c("bg-racers", {
          disappear: y > firstLeg + 700,
          appear: y > marker[1] + 150 && firstLeg + 500,
        })}
        style={{
          top: withinRacerSection ? marker[1] - RACER_START : -1500,
        }}
      >
        <div className={c("racer-1")} style={{ top: racer1top }}>
          <RaceOne />
        </div>
        <div className={c("racer-2")} style={{ top: racer2top }}>
          <RaceTwo />
        </div>
        <div className={c("racer-3")} style={{ top: racer3top }}>
          <RaceThree />
        </div>
      </div>
      {/* SECTION 3 */}
      <div className={c("bg-register")}>
        <img
          className={c("bg-register-item")}
          src={phoneImg}
          ref={registerRef}
          alt=""
        />
      </div>
    </div>
  );
};

// DESKTOP
function Desktop({ y, firstScroll, firstClick }) {
  const [isLoaded, setLoadStatus] = useState(false);

  let heroRef = useRef(null); // SECTION 1 - top
  let heroCaptionRef = useRef(null); // SECTION 2 - top
  let walkRef = useRef(null); //SECTION 3 - top
  let registerRef = useRef(null); // SECTION 4 - top
  let sponsorRef = useRef(null); // SECTION 5 - top
  let becomeSponsorRef = useRef(null); // SECTION 6 - top
  let donateRef = useRef(null); // SECTION 7 - top

  useEffect(() => {
    setLoadStatus(true);
    // eslint-disable-next-line
  }, []);

  // const handleScrollUp = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  const marker = {
    1:
      heroCaptionRef.current &&
      heroCaptionRef.current.offsetTop +
        heroCaptionRef.current.clientHeight / 2,
    3:
      registerRef.current &&
      registerRef.current.offsetTop - registerRef.current.clientHeight,
    6: donateRef.current && donateRef.current.offsetTop - 400,
  };

  // Meant for scrolling to positions
  // eslint-disable-next-line
  const SCROLL_POSITION = {
    0: heroRef.current && heroRef.current.offsetTop,
    1: heroCaptionRef.current && heroCaptionRef.current.offsetTop,
    2: walkRef.current && walkRef.current.offsetTop,
    3: registerRef.current && registerRef.current.offsetTop,
    4: sponsorRef.current && sponsorRef.current.offsetTop,
    5: becomeSponsorRef.current && becomeSponsorRef.current.offsetTop,
    6: donateRef.current && donateRef.current.offsetTop,
  };

  const w = window.innerWidth;
  const h = window.innerHeight;

  return (
    <div className={c("desktop", { loaded: isLoaded })}>
      <Parallax
        y={y}
        h={h}
        w={w}
        isLoaded={isLoaded}
        firstScroll={firstScroll}
        firstClick={firstClick}
        marker={marker}
      />
      <div className={c("main")}>
        <section
          className={c("hero")}
          style={{ marginBottom: h / 2 }}
          ref={heroRef}
        >
          <h1 className={c("hero-title")}>
            ANCOP
            <br />
            Virtual
            <br />
            Walk
          </h1>
          <p className={c("hero-caption")}>
            Thank you for your interest. Registrations and donations for the
            2020 ANCOP Virtual Walk have closed. We look forward to seeing you
            in 2021. Stay tuned for more updates.
          </p>
          <p className={c("hero-caption")}>
            Relive the first-ever virtual staging of the ANCOP Walk by watching
            the online closing event:
          </p>
          <a
            className={c("watch-link")}
            href="https://www.youtube.com/watch?v=06XwW0LNZcM"
          >
            Watch Now
          </a>
          {/* <div className={c("hero-caption")} ref={heroCaptionRef}>
            <div className={c("hero-logo")}>
              <img
                src={logoBigImg}
                className={c("hero-img")}
                alt="Answer the cry of the poor"
              />
              <AncopWalkLogo />
            </div>
            <p>
              The annual ANCOP Walk is ANCOP Canada's flagship fundraising event
              for the poor. It is an opportunity for us to raise awareness about
              our mission, and to celebrate the contributions of our
              administrators, sponsors, donors and volunteers across the world.
            </p>
          </div> */}
        </section>
        {/* <section
          className={c("walk")}
          style={{ marginBottom: h / 4 }}
          ref={walkRef}
        >
          <p>
            In light of the global COVID19 pandemic and the need to practice
            social distancing, the 17th annual ANCOP Walk will be held virtually
            from July 1 to August 22, 2020.
          </p>
          <p>
            This year's walkers will be able to join from the comfort of their
            own neighborhoods. During this 8-week period, walkers get to
            virtually track steps and make them count against millions of steps
            nationwide.
          </p>
        </section>
        <section className={c("register")} ref={registerRef}>
          <h2>WALK WITH US</h2>
          <p>
            Register below and get exclusive access the mobile app for the
            virtual walk.
          </p>
          <p>
            Join over 5000 walkers all over Canada who show their support and
            consequently, help improve the lives of the poor.
          </p>
          <div className={c("register-cta-container")}>
            <a
              className={c("register-cta")}
              href="https://secure.e2rm.com/registrant/startup.aspx?eventid=335408"
              aria-label="Register for ANCOP Virtual Walk"
            >
              Register Now
            </a>
            <a
              className={c("register-cta")}
              href="https://secure.e2rm.com/registrant/EventHome.aspx?eventid=335408&langpref=en-CA"
              aria-label="Login to the ANCOP Virtual Walk Fundraiser Page"
            >
              Login
            </a>
          </div>
        </section>
        <section className={c("d-sponsors")} ref={sponsorRef}>
          <h2>OUR SPONSORS</h2>
          <div className={c("d-sponsors-logos")}>
            {sponsorLogos.equalizer.length > 0 && (
              <>
                <h3>Equalizer</h3>
                <div className={c("d-sponsors-equalizer")}>
                  {sponsorLogos.equalizer.map((sponsor, i) => {
                    return (
                      <CompanyLogo key={i} type="equalizer" {...sponsor} />
                    );
                  })}
                </div>
              </>
            )}
            {sponsorLogos.gamechanger.length > 0 && (
              <>
                <h3>Game Changer</h3>
                <div className={c("d-sponsors-gamechanger")}>
                  {sponsorLogos.gamechanger.map((sponsor, i) => {
                    return (
                      <CompanyLogo key={i} type="gamechanger" {...sponsor} />
                    );
                  })}
                </div>
              </>
            )}
            {sponsorLogos.lifeline.length > 0 && (
              <>
                <h3>Life Line</h3>
                <div className={c("d-sponsors-lifeline")}>
                  {sponsorLogos.lifeline.map((sponsor, i) => {
                    return <CompanyLogo key={i} type="lifeline" {...sponsor} />;
                  })}
                </div>
              </>
            )}
            {sponsorLogos.trendsetter.length > 0 && (
              <>
                <h3>Trendsetter</h3>
                <div className={c("d-sponsors-trendsetter")}>
                  {sponsorLogos.trendsetter.map((sponsor, i) => {
                    return (
                      <CompanyLogo key={i} type="trendsetter" {...sponsor} />
                    );
                  })}
                </div>
              </>
            )}
            <h3>Donor List</h3>
            <div className={c("d-sponsors-donor-list")}>
              {donorList.map((donor) => (
                <span key={donor}>{donor}</span>
              ))}
            </div>
          </div>
        </section>
        <section className={c("donate")} ref={donateRef}>
          <h2>DONATE</h2>
          <p>
            Can't join the virtual walk? You can still help our mission by
            making a donation below.
          </p>
          <div className={c("donate-logo")}>
            <img
              src={logoBigImg}
              className={c("donate-img", { active: y > marker[6] })}
              alt="Answer the cry of the poor"
            />
          </div>
          <a
            className={c("donate-cta")}
            href="https://secure.e2rm.com/registrant/search.aspx?EID=335408&Lang=en-CA&Referrer=direct%2fnone"
            alt="Make a donation to help the poor"
          >
            Donate Now
          </a>
        </section>
        <section className={c("become-sponsor")} ref={becomeSponsorRef}>
          <h2>BECOME A SPONSOR</h2>
          <p>
            Get involved and be a partner in our mission. A variety of branding
            opportunities are available for sponsors.
          </p>
          <div className={c("become-sponsor-img")}>
            <Sponsors />
          </div>
          <a
            className={c("become-sponsor-cta")}
            href="/sponsors"
            alt="learn more about becoming a sponsor"
          >
            More Info
          </a>
        </section>
       */}
      </div>
      {/* <div className={c("page-nav")}>
        <button onClick={handleScrollUp}>
          <Arrow />
        </button>
      </div> */}
    </div>
  );
}

export default Desktop;
