import React from "react";
import classnames from "classnames/bind";

import Onboarding from "assets/2020-ANCOP-Virtual-Walk-Onboarding.pdf";
import FundraisingGuide from "assets/2020-ANCOP-Virtual-Walk-Fundraising-Guide.pdf";
import styles from "./Instructions.scss";
const c = classnames.bind(styles);

const Instructions = () => {
  return (
    <div className={c("instructions")}>
      <h1 className={c("instructions-title")}>How It Works</h1>
      <p className={c("instructions-title-caption")}>
        ANCOP's annual fundraiser has gone virtual! It's even easier to
        participate and raise funds for the poor, simply follow the steps below.
      </p>
      <section>
        <h2 className={c("instructions-section-title")}>
          <a href="/register">STEP 1 - REGISTER</a>
        </h2>
        <p className={c("instructions-section-content")}>
          Sign up for this year's ANCOP Virtual Walk and set your fundraising
          goal.
        </p>
        <div>
          <iframe
            title="Tutorial on how to register for the ANCOP Virtual Walk"
            width="560"
            src="https://www.youtube.com/embed/d7YfIbh6WZg"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <iframe
            title="Tutorial on how to join a team for the ANCOP Virtual Walk"
            width="560"
            src="https://www.youtube.com/embed/PNshj3QyYT0"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>
      <section>
        <h2 className={c("instructions-section-title")}>
          STEP 2 - SETUP DEVICE
        </h2>
        <p className={c("instructions-section-content")}>
          Download the application and sync your mobile phone or fitness device
          to start tracking steps.
        </p>
        <div>
          <iframe
            title="Tutorial on how to pair fitness device on app"
            width="560"
            src="https://www.youtube.com/embed/bB18sqYM_9s"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className={c("instructions-resources-container")}>
          <a
            href="http://help.movespring.com/en/articles/1419827-what-fitness-trackers-and-smartwatches-can-play"
            className={c("instructions-cta")}
            target="_blank"
            rel="noopener noreferrer"
          >
            List of Supported Devices
          </a>
          <a
            href="http://help.movespring.com/en/articles/1424995-how-do-i-connect-my-iphone-as-my-fitness-tracker"
            className={c("instructions-cta")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Connecting an iPhone
          </a>
          <a
            href="http://help.movespring.com/en/articles/3204335-how-do-i-connect-my-android-phone-as-my-fitness-tracker"
            className={c("instructions-cta")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Connecting an Android
          </a>
          <a
            href="http://help.movespring.com/en/articles/1420530-how-do-i-connect-my-fitbit"
            className={c("instructions-cta")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Connecting a Fitbit
          </a>
          <a
            href="http://help.movespring.com/en/articles/1421069-how-do-i-connect-my-garmin"
            className={c("instructions-cta")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Connecting a Garmin
          </a>
        </div>
      </section>
      <section>
        <h2 className={c("instructions-section-title")}>STEP 3 - FUNDRAISE</h2>
        <p className={c("instructions-section-content")}>
          Invite your friends and families to donate or solicit funds for ANCOP
          beneficiaries.
        </p>
        <div>
          <iframe
            title="Tutorial on how to fundraise for the ANCOP Virtual Walk"
            width="560"
            src="https://www.youtube.com/embed/CL_Q8FcdClU"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>
      <section>
        <h2 className={c("instructions-section-title")}>STEP 4 - WALK</h2>
        <p className={c("instructions-section-content")}>
          Virtually track your steps along with other ANCOP supporters.
        </p>
      </section>
      <section className={c("instructions-resources")}>
        <h2>Onboarding Resources:</h2>
        <div className={c("instructions-resources-container")}>
          <a href={Onboarding} className={c("instructions-cta")}>
            Download Participant Onboarding Presentation
          </a>
          <a href={FundraisingGuide} className={c("instructions-cta")}>
            Download Fundraising Guide
          </a>
        </div>
      </section>
    </div>
  );
};

export default Instructions;
